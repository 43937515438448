// OTF Format
// @font-face {
//     font-family: 'Tiny-Butler';
//     font-weight: normal;
//     src: url("../fonts/Tiny-Butler.otf") format("opentype");
// }

// TTF Format
// @font-face {
//     font-family: 'Asap';
//     font-weight: normal;
//     src: url('../fonts/Asap-Regular.ttf') format('truetype');
// }

@import 'https://use.fontawesome.com/releases/v5.0.8/css/all.css';
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700';
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700';
@import 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700';
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';

// set variable font
$font-primary: 'Open Sans', sans-serif !important;
$font-secondary: 'Roboto', sans-serif !important;
$font-10: 0.7143rem !important;
$font-11: 0.7857rem !important;
$font-12: 0.8571rem !important;
$font-13: 0.9286rem !important;
$font-14: 1rem !important;
$font-15: 1.071rem !important;
$font-16: 1.143rem !important;
$font-18: 1.286rem !important;
$font-20: 1.429rem !important;
$font-22: 1.571rem !important;
$font-24: 1.714rem !important;
$font-26: 1.857rem !important;
$font-28: 2rem !important;
$font-30: 2.143rem !important;
$font-32: 2.286rem !important;
$font-34: 2.429rem !important;
$font-36: 2.571rem !important;
$font-38: 2.714rem !important;
$font-40: 2.857rem !important;

.font-primary {
    font-family: $font-primary;
}
.font-secondary {
    font-family: $font-secondary;
}

// font size

.font-10 {
  font-size: $font-10 !important;
}

.font-11 {
  font-size: $font-11 !important;
}

.font-12 {
  font-size: $font-12 !important;
}

.font-13 {
  font-size: $font-13 !important;
}

.font-14 {
  font-size: $font-14 !important;
}

.font-15 {
  font-size: $font-15 !important;
}

.font-16 {
  font-size: $font-16 !important;
}

.font-18 {
  font-size: $font-18 !important;
}

.font-20 {
  font-size: $font-20 !important;
}

.font-22 {
  font-size: $font-22 !important;
}

.font-24 {
  font-size: $font-24 !important;
}

.font-26 {
  font-size: $font-26 !important;
}

.font-28 {
  font-size: $font-28 !important;
}

.font-30 {
  font-size: $font-30 !important;
}

.font-32 {
  font-size: $font-32 !important;
}

.font-34 {
  font-size: $font-34 !important;
}

.font-36 {
  font-size: $font-36 !important;
}

.font-38 {
  font-size: $font-38 !important;
}

.font-40 {
  font-size: $font-40 !important;
}

h1 {
  font-size: $font-34;
  font-family: $font-primary;
  font-weight: 700;
}

h2 {
  font-size: $font-24;
  font-family: $font-primary;
  font-weight: 700;
}

h3 {
  font-size: $font-20;
  font-family: $font-primary;
  font-weight: bold;
}

h4 {
  font-size: $font-18;
  font-family: $font-primary;
  font-weight: bold;
}

h5 {
  font-size: $font-16;
  font-family: $font-primary;
  font-weight: 500;
}

.font-light {
    font-weight: 300 !important;
}

.font-300 {
    font-weight: 300 !important;
}

.font-400 {
    font-weight: 400 !important;
}

.font-500 {
    font-weight: 500 !important;
}

.font-600 {
    font-weight: 600 !important;
}

.font-bold {
    font-weight: 600 !important;
}

.font-700 {
    font-weight: 700 !important;
}

strong {
  font-weight: 700;
}

.line-height-normal {
    line-height: normal !important;
}

.line-height-0 {
    line-height: 0 !important;
}

.line-height-1 {
    line-height: 1 !important;
}

.line-height-1-1 {
    line-height: 1.1 !important;
}

.line-height-1-5 {
    line-height: 1.5 !important;
}

.line-height-1-8 {
    line-height: 1.8 !important;
}

.line-height-2 {
    line-height: 2 !important;
}

.line-height-2-5 {
    line-height: 2.5 !important;
}

.line-height-3 {
    line-height: 3 !important;
}

$spacing-slug: spacing !default;

@for $i from 0 through 50 {
  .#{$spacing-slug}-#{$i} {
    letter-spacing: 1px * $i !important;
  }
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}



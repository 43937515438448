$color-primary: #17B44F;
$color-secondary: #A3DE5B;
$color-tertiary: #FCAE00;
$color-white: #fff;
$color-soft-white: #FAFAFA;
$color-black: #000;
$color-grey: #F6F6F6;
$color-red: #DC233C;
$color-green: #00b300;
$color-blue: #1869A8;
$color-yellow: #FCAE00;

$row-slug: row-custom !default;
$col-slug: col-custom !default;

@for $i from 0 through 20 {
  .#{$row-slug}-#{$i} {
    margin-left: -1px * $i !important;
    margin-right: -1px * $i !important;
  }
  .#{$row-slug}-#{$i} .#{$col-slug}-#{$i} {
    padding-left: 1px * $i !important;
    padding-right: 1px * $i !important;
  }
}

.color-primary {
  color: $color-primary !important;
}

.color-secondary {
  color: $color-secondary !important;
}

.color-tertiary {
  color: $color-tertiary !important;
}

.color-white {
  color: $color-white !important;
}

.color-soft-white {
  color: $color-soft-white !important;
}

.color-black {
  color: $color-black !important;
}

.color-grey {
  color: $color-grey !important;
}

.color-red {
  color: $color-red !important;
}

.color-green {
  color: $color-green !important;
}

.color-blue {
  color: $color-blue !important;
}

.color-yellow {
  color: $color-yellow !important;
}

// set background

.bg-light {
  background-color: $color-white !important;
}

.bg-white {
  background-color: $color-white !important;
}

.bg-dark {
  background-color: $color-black !important;
}

.bg-black {
  background-color: $color-black !important;
}

.bg-primary {
  background-color: $color-primary !important
}

.bg-secondary {
  background-color: $color-secondary !important;
}

.bg-tertiary {
  background-color: $color-tertiary !important;
}

.bg-soft-white {
  background-color: $color-soft-white !important;
}

.bg-grey {
  background-color: $color-grey !important;
}

.bg-red {
  background-color: $color-red !important;
}

.bg-green {
  background-color: $color-green !important;
}

.bg-blue {
  background-color: $color-blue !important;
}

.bg-yellow {
  background-color: $color-yellow !important;
}
html, body {
    font-size: 14px;
    font-family: $font-primary;
}

.wrapper {
    padding-top: 80px;
    min-height: 700px;
}

.hidden {
    display: none !important;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: $color-primary;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.img-fix {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.img-fix2 {
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.h-100 {
  height: 100% !important;
}

.h-50 {
  height: 50% !important;
}

.custom-select {
  border-radius: 2px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+) no-repeat 100% 50%;
  border: none;
  border: 1px solid #e4e4e4;
  outline: none;
  font-size: $font-13;
  padding: 5px 12px;
  height: 36px;
  transition: .3s;
  color: rgba(0, 0, 0, .6);
}

.custom-select:focus {
  outline: none;
  border-color: $color-primary;
}

.box-shadow {
  box-shadow: 0 5px 17px 3px rgba(0, 0, 0, 0.1);
}

label {
    line-height: normal;
}

label.error {
    color: rgba(255, 255, 255, .7);
    font-size: $font-13;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 0px;
}

label#inlineRadioOptions-error {
    position: absolute;
    bottom: -3px;
    left: 0px;
}

label#subscribe-error {
    position: absolute;
    bottom: -25px;
    left: 0px;
}

label#inlineRadioOptions-error~span {
    margin-bottom: 18px;
}

hr.transparent {
  border-color: transparent;
}

hr.short-line {
    width: 40px;
    margin-top: .3rem;
    margin-bottom: 1.4rem;
    border-width: 2px;
    border-color: $color-primary;
}

hr.short-line.left {
    width: 40px;
    margin: 0px;
    margin-top: .3rem;
    margin-bottom: 1.4rem;
    border-width: 2px;
    border-color: $color-primary;
}

input[type=text],
input[type=email],
input[type=password] {
    height: 36px;
}

input[type=text],
input[type=email],
input[type=password],
textarea {
    outline: none;
    box-shadow: none;
    border-radius: 2px;
    border: 1px solid #e4e4e4;
    font-size: 14px;
    padding: 10px 12px;
}

input:focus,
textarea:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $color-primary;
}


input::placeholder,
textarea::placeholder {
    font-size: $font-13;
    color: rgba(0, 0, 0, .4);
}

button,
button {
    outline: none;
    box-shadow: none;
    border-color: transparent;
}

button:hover {
    cursor: pointer;
}

button:focus,
button:active {
    outline: none !important;
    box-shadow: none;
    border-color: transparent;
}

.btn-border-primary {
    outline: none;
    border: 1px solid $color-primary;
    background-color: transparent;
    color: #000;
    display: inline-block;
    text-align: center;
    font-size: $font-13;
    padding: 6px 10px;
    font-weight: 400;
    border-radius: 3px;
    transition: .3s;
}

.btn-border-primary.active {
    border-color: $color-primary;
    background-color: $color-primary;
    opacity: .87;
    color: #fff;
    pointer-events: none;
}

.btn-border-primary:hover,
.btn-border-primary:focus,
.btn-border-primary:active,
.btn-border-primary:not(:disabled):not(.disabled):active {
    border-color: $color-primary;
    background-color: $color-primary;
    opacity: .87;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}

.btn-border-secondary {
    outline: none;
    border: 1px solid $color-secondary;
    background-color: transparent;
    color: #000;
    display: inline-block;
    text-align: center;
    font-size: $font-13;
    padding: 6px 40px;
    font-weight: 400;
    border-radius: 3px;
    transition: .3s;
}

.btn-border-secondary.active {
    border-color: $color-secondary;
    background-color: $color-secondary;
    opacity: 1;
    color: #fff;
    pointer-events: none;
}

.btn-border-secondary:hover,
.btn-border-secondary:focus,
.btn-border-secondary:active,
.btn-border-secondary:not(:disabled):not(.disabled):active {
    border-color: $color-secondary;
    background-color: $color-secondary;
    opacity: .87;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}


.btn-border-black {
    outline: none;
    border: 1px solid #000;
    background-color: transparent;
    color: #000;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    padding: 4px 30px;
    font-weight: 400;
    border-radius: 3px;
    transition: .3s;
}

.btn-border-black.active {
    border-color: #000;
    background-color: #000;
    opacity: 1;
    color: #fff;
    pointer-events: none;
}

.btn-border-black:hover,
.btn-border-black:focus,
.btn-border-black:active,
.btn-border-black:not(:disabled):not(.disabled):active {
    border-color: #000;
    background-color: #000;
    opacity: .87;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}


.btn-primary {
    outline: none;
    border-color: transparent;
    background-color: $color-primary;
    color: #fff;
    font-size: $font-13;
    padding: 6px 40px;
    font-weight: 400;
    border-radius: 3px;
    transition: .3s;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active {
    border-color: transparent;
    background-color: $color-primary;
    opacity: .87;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}

.btn-white {
    background-color: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;
    font-size: $font-13;
    padding: 6px 40px;
    font-weight: 400;
    border-radius: 3px;
    transition: .3s;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white:not(:disabled):not(.disabled):active {
    background-color: $color-primary;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
}

/*======= CUSTOM STYLE ========*/

.container-custom {
    padding: 40px 150px;
}

.navbar {
    font-size: 14px;
    padding: 0px 100px;
    font-weight: 300;
    font-family: 'Roboto';
}

.navbar-brand img {
    height: 70px;
}

.navbar {
    position: fixed;
    width: 100%;
    z-index: 2;
    box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.breadcrumbs {
  padding: 15px 100px;
  font-weight: 600;
}

.breadcrumbs a {
  font-weight: 400;
  color: #000000;
}

.breadcrumbs i {
  color: rgba(0, 0, 0, 0.5);
  margin: 0px 10px;
}

footer {
    .footer-section {
        padding: 50px 100px;
    }
    .footer-section-bottom {
        padding: 10px 100px;
    }
    background-color: #000;
    color: #fff;
}


footer .copyright {
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
}


footer .social-icon {
    a {
        color: #fff;
    }
    padding: 10px;
}

footer .social-icon i {
    font-size: 20px;
    margin: 5px 10px;
}

footer {
    .link-footer {
        font-size: 14px;
        line-height: 2;
        a {
            color: #fff;
            transition: .3s;
        }
    }
}

// responsive

@media (max-width: 1199px) {
}

@media (max-width: 992px) {
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
}

@media (max-width: 380px) {
}